/*\!*@tailwind base;*!*/

/**
 * Custom base styles
 */

/* body {
	font-size: 14px !important;
	line-height: 1.4 !important;
	overflow-x: hidden !important;
	font-feature-settings: 'salt' !important;
} */

/* html,
body {
	display: flex;
	flex-direction: column;
	position: relative;
	margin: 0;
	min-height: 100%;
	width: 100%;
	flex: 1 1 auto;
	@apply tt-bg-gray-50;
	-webkit-font-smoothing: auto;
	-moz-osx-font-smoothing: auto;
} */

html {
	font-size: 62.5% !important;
}

#root {
	width: 100%;
	height: 100%;
}

.MuiModal-root,
.MuiAutocomplete-popper {
	z-index: 99999;
}
.MuiTooltip-popper {
	z-index: 99999 !important;
}
.MuiPickersPopper-root {
	z-index: 99999 !important;
}

.MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar {
	display: none;
}

#track-root {
	@apply tt-text-gray-900;
	@apply tt-bg-gray-50;

	font-size: 14px;
	line-height: 1.4;

	overflow-x: hidden;
	font-feature-settings: 'salt';

	font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Inter var', Oxygen, Ubuntu,
		Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

	* {
		/* Text rendering */
		text-rendering: optimizeLegibility;
		-o-text-rendering: optimizeLegibility;
		-ms-text-rendering: optimizeLegibility;
		-moz-text-rendering: optimizeLegibility;
		-webkit-text-rendering: optimizeLegibility;
		-webkit-tap-highlight-color: transparent;
		font-family: inherit;
	}

	* :focus {
		outline: none !important;
	}

	.mantine-UnstyledButton-root {
		stop {
			stop-color: #0f766e !important;
		}
	}

	h1,
	.h1 {
		font-size: 24px;
	}

	h2,
	.h2 {
		font-size: 20px;
	}

	a {
		text-decoration: none !important;
	}

	h3,
	.h3 {
		font-size: 16px;
	}

	h4,
	.h4 {
		font-size: 15px;
	}

	h5,
	.h5 {
		font-size: 13px;
	}

	h6,
	.h6 {
		font-size: 12px;
	}

	.ps > .ps__rail-y,
	.ps > .ps__rail-x {
		z-index: 99;
	}

	a[role='button'] {
		text-decoration: none;
	}

	[role='tooltip'] {
		z-index: 9999;
	}

	.tt-scrollbar-x {
	}

	/* Medium Devices, Desktops Only */
	@media only screen and (min-width: 992px) {
		::-webkit-scrollbar {
			width: 8px;
			height: 8px;
			background-color: rgba(0, 0, 0, 0);
		}

		::-webkit-scrollbar:hover {
			width: 8px;
			height: 8px;
			background-color: rgba(0, 0, 0, 0.06);
		}

		::-webkit-scrollbar-thumb {
			border: 2px solid transparent;
			border-radius: 20px;
			box-shadow: rgba(0, 0, 0, 0.24) 0 0 0 20px inset;
		}

		::-webkit-scrollbar-thumb:active {
			border-radius: 20px;
		}
	}

	form label {
		z-index: 99;
	}

	body.no-animate *,
	body.no-animate *::before,
	body.no-animate *::after {
		transition: none !important;
		animation: none !important;
	}

	button:focus {
		outline: none;
	}

	/* Removes webkit's autofill backgorund color */
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		transitiondelay: 9999s;
		transitionproperty: background-color, color;
	}

	:focus {
		outline-color: transparent;
	}

	.FusePageSimple-contentWrapper,
	.FusePageSimple-wrapper,
	.FusePageSimple-root {
		background: none !important;
	}

	.MuiDrawer-root {
		min-height: 100%;
		/* flex: 1 1 auto; */
	}

	/*fullcalendar Fix*/
	.fc-scrollgrid-section-liquid {
		height: 1px !important;
	}

	.MuiSkeleton-root {
		transform: scale(1);
	}

	.task-card-select {
		height: 22px;
		max-height: 22px;
		min-height: 26px;
		font-size: 12px;
		border-radius: 22px;
	}

	.task-card-select .Mui-disabled {
		color: white !important;
		-webkit-text-fill-color: white !important;
	}

	.dialig-input {
		/* height: 42px !important; */
	}

	.dialig-input.select-multiple {
		/* min-height: 42px !important;
        height: auto !important; */
	}

	.dialig-input.task-dialog-input {
		height: auto;
	}

	.dialig-input.task-dialog-input .muiltr-1t630aw-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
		padding: 5px 14px !important;
	}

	.dialig-input .MuiSelect-select {
		min-height: auto;
	}

	.dialig-input .MuiInputBase-root {
		/* height: 42px; */
		border-radius: 6px;
	}

	.z-full {
		z-index: 99999;
	}

	.muiltr-khdx6p-MuiInputBase-root-MuiTablePagination-select {
		line-height: 2 !important;
	}

	.avatar-border-white {
		border-color: white !important;
	}

	@media (min-width: 1280px) {
		.FusePageSimple-contentWrapper .container {
			max-width: 100% !important;
		}
	}
	@media (min-width: 960px) {
		.FusePageSimple-contentWrapper .container {
			max-width: 100% !important;
		}
	}
	@media (min-width: 600px) {
		.FusePageSimple-contentWrapper .container {
			max-width: 100% !important;
		}
	}

	.FusePageSimple-contentWrapper .container {
		max-width: 100% !important;
	}

	.task-card-select .MuiSelect-select {
		min-height: auto;
		font-size: 14px;
	}

	.MuiTextField-root p {
		margin-top: 0px;
	}

	.task-card-form-input {
		@apply tt-h-48 tt-w-full;
	}

	.task-card-form-input .MuiOutlinedInput-root {
		@apply tt-rounded-6;
	}

	.ffs {
		font-feature-settings: 'pnum' on, 'lnum' on;
	}

	.task-card-form-input .muiltr-1ka647-MuiFormLabel-root-MuiInputLabel-root {
		transform: translate(14px, 13px) scale(1);
	}

	.border-b-my {
		border-bottom-width: 1.5px;
	}

	.task-card-select .MuiOutlinedInput-notchedOutline {
		border: none;
	}

	.task-card-select svg {
		@apply tt-text-white;
	}

	.scrumboard-page .FusePageSimple-wrapper {
		@apply tt-bg-white;
	}

	.bg-my-white {
		background-color: #fff !important;
	}

	/* .rich-text .css-1rh39ed {
        margin: 0;
        padding: 16px 8px;
        @apply bg-gray-100;
    } */
	.muiltr-zj09af.MuiSelect-select,
	.css-bni3t0-MuiSelect-select-MuiInputBase-input.MuiSelect-select {
		min-height: 1em;
	}

	.board-add-input .MuiFilledInput-root {
		@apply tt-bg-white tt-shadow;
	}

	.board-add-input div {
		@apply tt-rounded-lg;
	}

	.notif-text {
	}

	.notif-text > h1,
	.notif-text > h2,
	.notif-text > h3,
	.notif-text > h4,
	.notif-text > a,
	.notif-text > span {
		font-size: 14px !important;
		font-weight: 500 !important;
		margin: 0 !important;
	}

	.FusePageSimple-contentWrapper {
		background-color: transparent;
		padding: 0 !important;
	}

	.FusePageSimple-contentWrapper {
		overflow: auto !important;
	}

	.tab-badge {
		border-radius: 10px;
		height: 20px;
		padding: 0 6px;
		min-width: 20px;
		position: static;
		color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		font-weight: 500;
	}

	.text-my-white td,
	.text-my-white svg {
		color: white !important;
		fill: white !important;
	}

	.is-loading-hide {
		z-index: -10 !important;
	}

	.is-loading {
		z-index: 999999 !important;
	}

	.bg-red {
		background-color: rgba(255, 0, 0, 0.2);
	}

	.bg-green {
		background-color: rgba(0, 128, 0, 0.2);
	}

	.bg-red {
		background-color: rgba(255, 0, 0, 0.2);
	}

	.bg-green {
		background-color: rgba(0, 128, 0, 0.2);
	}

	.MuiTablePagination-select {
		min-height: auto !important;
	}

	.MuiSnackbar-root {
		left: 50% !important;
		transform: translateX(-50%) !important;
		right: auto !important;
		z-index: 999999999;
	}
	.MuiChip-root {
		height: 24px !important;
	}

	.tt-anywhere {
		line-break: anywhere;
	}

	.new-select {
		height: 32px !important;
		min-height: 32px !important;
		border-radius: 32px !important;
	}

	.new-select.select-multiple {
		min-height: 32px !important;
		height: auto !important;
	}

	.new-select.task-dialog-input {
		height: auto !important;
	}

	.new-select.task-dialog-input .muiltr-1t630aw-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
		padding: 5px 14px !important;
	}

	.new-select {
		.MuiSelect-select,
		.MuiInputBase-input {
			min-height: auto !important;
		}
	}

	.new-select .MuiInputBase-root {
		height: 32px !important;
		border-radius: 32px !important;
	}

	.comment__branches {
		display: flex;
		width: 20px;
	}

	.comment__branch {
		width: 2px;
		border-left: 1px solid #e0e0e0;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-webkit-tap-highlight-color: transparent;
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}

	.comment__branch::before {
		content: '';
		-webkit-box-sizing: content-box;
		box-sizing: content-box;
		display: block;
		width: 12px;

		padding-bottom: 20px;
		border: solid #e0e0e0;
		border-width: 0 0 1px 1px;
		border-bottom-left-radius: 8px;
		margin-left: -1px;
	}

	.task-branch::before {
		content: '';
		-webkit-box-sizing: content-box;
		box-sizing: content-box;
		display: block;
		width: 8px;
		height: 15px;
		border: solid #e0e0e0;
		border-width: 0 0 2px 1px;
		border-bottom-left-radius: 8px;
		margin-left: 0;
	}

	.filters .MuiChip-root {
		height: 32px !important;
	}

	.MuiChip-root span {
		padding: 0 7px;
	}

	.MuiAutocomplete-popper {
		z-index: 99999;
	}

	:root {
		color-scheme: light !important;
	}

	.FusePageSimple-contentWrapper {
		z-index: 5 !important;
	}

	#ReactSimpleImageViewer {
		z-index: 9999999 !important;
	}

	.text-input .MuiInputBase-root {
		min-height: auto !important;
	}

	/* Hide scrollbar for Chrome, Safari and Opera */
	.filters::-webkit-scrollbar {
		display: none;
	}

	/* Hide scrollbar for IE, Edge and Firefox */
	.filters {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}

	.filters .filter-wrap {
		flex-wrap: nowrap !important;
		overflow: hidden;
	}

	.filter-select-miltipe .MuiChip-root {
		height: 22px !important;
	}

	.filter-select-miltipe.new-select.select-multiple {
		height: 32px !important;
	}

	.my-truncate {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	.my-truncate-one {
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	.select-border {
		border-color: rgba(0, 0, 0, 0.23) !important;
	}

	.select-border:hover {
		border-color: rgb(17, 24, 39) !important;
	}

	.sc-breuTD {
		max-height: 400px !important;
		min-height: auto !important;
	}

	.null-button {
		@apply tt-p-0 tt-bg-transparent tt-text-14 tt-border-transparent hover:tt-bg-transparent #{!important};
	}

	.row-hover-first {
		display: none !important;
	}
	.row-hover-second {
		display: flex !important;
	}

	.row-hover:hover {
		.row-hover-first {
			display: flex !important;
		}
		.row-hover-second {
			display: none !important;
		}
	}

	.table-scroll {
		overflow: scroll !important;
	}

	.text-hyphens {
		-webkit-hyphens: auto;
		-webkit-hyphenate-limit-before: 3;
		-webkit-hyphenate-limit-after: 3;
		-webkit-hyphenate-limit-chars: 6 3 3;
		-webkit-hyphenate-limit-lines: 2;
		-webkit-hyphenate-limit-last: always;
		-webkit-hyphenate-limit-zone: 8%;

		-moz-hyphens: auto;
		-moz-hyphenate-limit-chars: 6 3 3;
		-moz-hyphenate-limit-lines: 2;
		-moz-hyphenate-limit-last: always;
		-moz-hyphenate-limit-zone: 8%;

		-ms-hyphens: auto;
		-ms-hyphenate-limit-chars: 6 3 3;
		-ms-hyphenate-limit-lines: 2;
		-ms-hyphenate-limit-last: always;
		-ms-hyphenate-limit-zone: 8%;

		hyphens: auto;
		hyphenate-limit-chars: 6 3 3;
		hyphenate-limit-lines: 2;
		hyphenate-limit-last: always;
		hyphenate-limit-zone: 8%;
	}
	.filters {
		.MuiInputBase-root,
		.filter-select-miltipe.new-select.select-multiple,
		.new-select {
			height: 28px !important;
			min-height: 28px !important;
		}
		.MuiSelect-select {
			padding: 10px !important;
		}
	}

	.select-input-hide-icon {
		svg {
			display: none;
		}
	}
}
